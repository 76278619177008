import React, { ChangeEvent, useState, ChangeEventHandler } from 'react'
import { PageProps, Link } from 'gatsby'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Container from '@layouts/container/container'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'
import Metadata from '@components/atoms/metadata/metadata'
import Breadcrumbs from '@components/molecules/breadcrumbs/breadcrumbs'

import styles from './courses.module.scss'

type Props = PageProps & {
    pageContext: {
        courses: Course[]
    }
}

const CoursesTemplate = ({ pageContext: { courses }, location }: Props) => {
    const [displayedCourses, setDisplayedCourses] = useState(courses)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchTerm = new RegExp(e.target.value, 'i')
        const filteredCourses = courses.filter(({ name, content: { summary } }) => searchTerm.test(name) || searchTerm.test(summary))
        setDisplayedCourses(filteredCourses)
    }

    const breadcrumbs = [{ label: 'Courses' }]

    return (
        <Container courses={courses}>
            <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
            <Metadata title="MW Training | Courses" url={location?.href} />
            <input onChange={handleChange} className={styles.search} placeholder="Search" />
            <TransitionGroup in="true" className={styles.list} component="ul">
                {displayedCourses.map(({ name, full_slug, content: { summary, _uid } }, i) => {
                    const link = `/${full_slug}`
                    let description = summary
                    if (summary.length >= 150) {
                        description = description.substr(0, 200)
                        description = description.substr(0, Math.min(description.length, description.lastIndexOf(' '))) + '...'
                    }

                    return (
                        <CSSTransition
                            classNames={{
                                enter: styles.cardEnter,
                                enterActive: styles.cardEnterActive,
                                exit: styles.cardLeave,
                                exitActive: styles.cardLeaveActive
                            }}
                            key={full_slug}
                            timeout={200}
                        >
                            <li className={styles.card}>
                                <Link className={styles.link} to={link}>
                                    <Heading className={styles.cardHeading} type={Types.H3} text={name} />
                                    <Text className={styles.cardDescription}>{description}</Text>
                                    <Text className={styles.readMore}>
                                        Read More <i className="icon icon-right"></i>
                                    </Text>
                                </Link>
                            </li>
                        </CSSTransition>
                    )
                })}
            </TransitionGroup>
        </Container>
    )
}

export default CoursesTemplate

import React, { Fragment } from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'

import styles from './breadcrumbs.module.scss'

export enum Icons {
    Send = 'icon-send',
    ArrowDown = 'icon-down',
    ArrowRight = 'icon-right'
}

type Item = {
    label: string
    link?: string
}

interface Props {
    className?: string
    items: Item[]
}

const Breadcrumbs = ({ className, items }: Props) => {
    const containerClass = cn(styles.breadcrumbs, className)
    return (
        <ul className={containerClass}>
            <li>
                <Link className={styles.link} to="/">
                    Home
                </Link>
            </li>
            <li className={styles.chevron}>/</li>
            {items.map(({ label, link }) => (
                <Fragment key={label}>
                    <li>
                        {link ? (
                            <Link className={styles.link} to={link}>
                                {label}
                            </Link>
                        ) : (
                            label
                        )}
                    </li>
                    {!!link && <li className={styles.chevron}>/</li>}
                </Fragment>
            ))}
        </ul>
    )
}

export default Breadcrumbs
